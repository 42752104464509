<template>

<app-item :route="(item.id) ? 'Category' : 'Category.Groups'" :id="item.id" id-name="category" :selected="selected" v-on:shiftclick="$emit('shiftclick')">

	<app-item-badge :image="(item.id) ? item.icon : false" :icon="(item.id) ? false : item.icon" :color="item.background" :invisible="!item.visible" />

	<app-item-title :text="$highlight(item.title, highlight)" />

	<app-item-count route="Category.Groups" :params="{category: item.id}" tooltip="Manage groups" :count="groupCount" />

	<app-item-drag :disabled="!item.id" />

</app-item>

</template>

<script>

export default {

	props: ['item', 'highlight', 'selected'],

	computed: {

		groupCount: function() {

			return this.$store.getters['groups/find/category'](this.item.id).length

		}

	}

}

</script>

<style scoped>

</style>
